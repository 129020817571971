import { Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledBox = styled(Box)`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

interface YoutubeEmbedProps {
  id: string;
  sx?: any; // For styling properties
}

const YoutubeEmbed = ({ id, sx }: YoutubeEmbedProps) => (
  <StyledBox sx={sx}>
    <iframe id="player" src={`https://www.youtube-nocookie.com/embed/${id}`}></iframe>
  </StyledBox>
);

export default YoutubeEmbed;

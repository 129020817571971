import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "../provider/auth-provider";
import { useNavigate } from "react-router-dom";
import useContentful from "../hooks/useContentful";
import { StyledButtonInverted } from "../components/customs";
import LoadingWrapper from "./pageLoading";
import { TypeHome } from "../types/contentful";
import { LanguageContext } from "../provider/language-provider";
import { getDescription } from "../components/richt-text-contentful";
import YoutubeEmbed from "../components/youtube-embed";

const HomePage = () => {
  const { currentUser } = useContext(AuthContext)!;
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const { language } = useContext(LanguageContext)!;
  const fields = (useContentful("home") as TypeHome<"WITH_ALL_LOCALES">).fields;

  const handleStartClick = () => {
    if (currentUser?.appUser) {
      navigate("/start");
    } else {
      navigate("/login");
    }
  };

  if (!fields) {
    return <LoadingWrapper title={"Loading Application..."} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: {
          xs: "center", // Center the button on extra-small screens
          sm: "flex-start", // Align to the start on small screens and above
        },
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: {
          xs: 0, // paddingLeft for extra-small screens
          sm: "1.25em", // paddingLeft for small screens and above
        },
        paddingRight: {
          xs: 0, // paddingRight for extra-small screens
          sm: "1.25em", // paddingRight for small screens and above
        },
      }}
    >
      <Box
        sx={{
          marginTop: "1.5em",
          marginBottom: "5em",
        }}
      >
        <Typography variant={isXs ? "h2" : "h1"} sx={{ mt: 2 }}>
          {fields.title && fields.title[language]}
        </Typography>
        <Typography variant={isXs ? "h5" : "h2"} sx={{ mt: 2 }}>
          {fields.subtitle && fields.subtitle[language]}
        </Typography>
        {fields?.youtubeId && fields.youtubeId[language] && (
          <YoutubeEmbed id={fields.youtubeId[language] || ""} sx={{ mt: 4 }} />
        )}

        <Typography variant="body1" component={"div"} sx={{ mt: 2 }}>
          {getDescription(fields?.description ? fields.description[language] : null)}
        </Typography>
      </Box>
      <Box>
        <StyledButtonInverted
          sx={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
          onClick={handleStartClick}
        >
          {fields.buttonStart && fields.buttonStart[language]}
        </StyledButtonInverted>
      </Box>
    </Box>
  );
};

export default HomePage;

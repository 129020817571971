import { useContext, useEffect, useState } from "react";
import PageWrapper from "../components/application-wrapper";
import { AuthContext } from "../provider/auth-provider";
import { ValueContext } from "../provider/value-provider";
import { useLocation, useNavigate } from "react-router-dom";
import { LanguageContext } from "../provider/language-provider";
import useContentful from "../hooks/useContentful";
// import { getAnalytics, logEvent } from "firebase/analytics";
import { TypeSelect } from "../types/contentful";
import { DEFAULT_LANGUAGE } from "../types/languages";
import env from "../env-config";
import { Value } from "../types/values";
import LoadingWrapper from "./pageLoading";
import CustomStepper from "../components/custom-stepper";
import { Alert, AlertTitle, Button, Grid, Typography } from "@mui/material";
import Spacer from "../components/spacer";
import { StyledButton, StyledButtonValue, StyledTypographyValue } from "../components/customs";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgressWithLabel from "../components/linear-progress-with-label";
import YoutubeEmbed from "../components/youtube-embed";
import { getDescription } from "../components/richt-text-contentful";

const SelectPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let idx: number = parseInt(location.search.replace("?index=", ""), 10);
  idx = isNaN(idx) ? 0 : idx;

  const [showVideo, setShowVideo] = useState(env.dev.videos);
  const { isLoading } = useContext(AuthContext)!;

  const { values, handleUpdate, bumpSelectedToAdjusted, initValues, initialized } =
    useContext(ValueContext)!;
  const [index, setIndex] = useState(idx);

  const progress = (index / values.length) * 100;
  const selected = values.filter((e) => e.selected === true || e.selected === false);
  const selectedTrue = values.filter((e) => e.selected === true);

  const { currentUser } = useContext(AuthContext)!;

  const { language } = useContext(LanguageContext)!;
  const fields = (useContentful("select") as TypeSelect<"WITH_ALL_LOCALES">).fields;

  const value: Value = values[index];

  const valueTranslation =
    values.length > index
      ? values[index].translations[language]
        ? values[index].translations[language]
        : values[index].translations[DEFAULT_LANGUAGE]
      : { value: "", warning: "", info: "" };

  useEffect(() => {
    console.log("Initialized: ", initialized);
    if (initialized) {
      initValues();
    }
  }, [initialized]);

  useEffect(() => {
    if (selected.length < values.length || values.length === 0) {
      return;
    }

    if (selectedTrue.length < 2) {
      navigate(`/no-values`);
      return;
    }

    console.log("selectedTrue.length: ", selectedTrue.length);
    console.log("env.adjusted.max: ", env.adjusted.max);

    if (selectedTrue.length > env.selected.max) {
      navigate(`/reduce`);
      return;
    }

    if (selectedTrue.length > env.adjusted.max) {
      navigate(`/adjust`);
      return;
    }

    bumpSelectedToAdjusted();
    navigate(`/focus`);
  }, [selected, selectedTrue, values]);

  function handleYes(value: Value) {
    handleUpdate(value.id, { selected: true });
    goNext();
  }

  function handleNo(value: Value) {
    handleUpdate(value.id, { selected: false });
    goNext();
  }

  function goNext() {
    if (index < values.length) {
      //   const analytics = getAnalytics();
      //   logEvent(analytics, "screen_view", {
      //     screen_name: `selected_index_${index}`,
      //   });
      setIndex(index + 1);
    }
  }

  if (currentUser?.appUser && currentUser.appUser.availableTests === 0) {
    navigate(`/`);
  }

  if (values.length === 0 || !fields || !value || isLoading) {
    return <LoadingWrapper title="Loading Application..." />;
  }

  if (showVideo) {
    return (
      <PageWrapper>
        <Typography variant="h6" gutterBottom>
          {fields.videoTitle && fields.videoTitle[language]}
        </Typography>
        <Spacer />
        {fields.videoYoutubeId && fields.videoYoutubeId[language] && (
          <YoutubeEmbed id={fields.videoYoutubeId[language] || ""} />
        )}
        <Spacer />
        <Typography gutterBottom>
          {getDescription(fields?.videoDescription ? fields.videoDescription[language] : null)}
        </Typography>

        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => {
            setShowVideo(false);
            window.scrollTo(0, 0);
          }}
        >
          {(fields.videoButton && fields.videoButton[language]) || "Weiter"}
        </StyledButton>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <CustomStepper activeStep={0} />
      <Spacer />
      <Typography variant="h6">{fields.title && fields.title[language]}</Typography>
      <Spacer i={2} />
      <StyledTypographyValue align="center">{valueTranslation.value}</StyledTypographyValue>
      <Spacer />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} container alignItems="center">
          <StyledButtonValue
            variant="contained"
            fullWidth
            size="large"
            color="primary"
            onClick={() => handleYes(value)}
            startIcon={<CheckIcon />}
          >
            {fields.buttonYes && fields.buttonYes[language]}
          </StyledButtonValue>
        </Grid>

        <Grid item xs={12} sm={6} container alignItems="center">
          <StyledButtonValue
            variant="contained"
            fullWidth
            size="large"
            color="primary"
            startIcon={<CloseIcon />}
            onClick={() => handleNo(value)}
          >
            {fields.buttonNo && fields.buttonNo[language]}
          </StyledButtonValue>
        </Grid>
      </Grid>
      {valueTranslation.warning && (
        <>
          <Alert severity="warning">
            <AlertTitle>{fields.warningCaption && fields.warningCaption[language]}</AlertTitle>
            {valueTranslation.warning}
          </Alert>
        </>
      )}
      {/* {value.info && (
        <>
          <Alert severity="info">
            <AlertTitle>{fields.infoCaption}</AlertTitle>
            {value.info}
          </Alert>
        </>
      )} */}
      <Spacer i={2} />
      <LinearProgressWithLabel value={progress} />
      <Spacer />
      {index > 0 && (
        <Button variant="outlined" onClick={() => setIndex(index - 1)}>
          {fields.buttonBack && fields.buttonBack[language]}
        </Button>
      )}
    </PageWrapper>
  );
};

export default SelectPage;

import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LanguageContext } from "../provider/language-provider";
import { ValueContext } from "../provider/value-provider";
import { TypeFocus } from "../types/contentful";
import useContentful from "../hooks/useContentful";
import usePairedValues from "../hooks/usePairedValues";
import { Value } from "../types/values";
import LoadingWrapper from "./pageLoading";
import PageWrapper from "../components/application-wrapper";
import { Grid, Typography } from "@mui/material";
import Spacer from "../components/spacer";
import { getDescription } from "../components/richt-text-contentful";
import { StyledButton, StyledButtonValue } from "../components/customs";
import CustomStepper from "../components/custom-stepper";
import { DEFAULT_LANGUAGE } from "../types/languages";
import LinearProgressWithLabel from "../components/linear-progress-with-label";
import { AuthContext } from "../provider/auth-provider";
import { updateUser } from "../data-functions/users-api";
import YoutubeEmbed from "../components/youtube-embed";
import env from "../env-config";

const FocusPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let idx: number = parseInt(location.search.replace("?index=", ""), 10);
  idx = isNaN(idx) ? 0 : idx;

  const [index, setIndex] = useState(idx);
  const [end, setEnd] = useState(false);

  const [showStartVideo, setStartShowVideo] = useState(env.dev.videos);

  const { language } = useContext(LanguageContext)!;
  const { initialized, values, handleUpdate, persistValues, getValue } = useContext(ValueContext)!;
  const { currentUser, setCurrentUser } = useContext(AuthContext)!;

  const fields = (useContentful("focus") as TypeFocus<"WITH_ALL_LOCALES">).fields;

  const paired = usePairedValues(values);
  const progress = (index / paired.length) * 100;

  function bump(value: Value) {
    const vl = getValue(value.id);
    if (vl) {
      handleUpdate(value.id, { focused: vl.focused + 1 });
    }

    // const analytics = getAnalytics();
    // logEvent(analytics, "screen_view", {
    //   screen_name: `focused_index_${index}`,
    // });

    if (index + 1 < paired.length) {
      setIndex(index + 1);
    } else {
      if (currentUser?.id && currentUser.appUser) {
        currentUser.appUser.availableTests = Math.max(currentUser.appUser.availableTests - 1, 0);
        setCurrentUser(currentUser);
        updateUser(currentUser.id, { availableTests: currentUser.appUser.availableTests });
      }

      setEnd(true);
    }
  }

  const handlePersistAndPush = async () => {
    console.log("hanlde persist and push");
    const id = await persistValues();
    console.log(`persisted result with id: ${id}`);
    navigate(`/results/${id}`);
  };

  if (values.length === 0 && initialized) {
    navigate("/");
  }

  if (values.length === 0 || !fields) {
    return <LoadingWrapper />;
  }

  if (paired.length === 0 || index > paired.length) {
    return null;
  }

  const value0 = paired[index][0];
  const value1 = paired[index][1];

  if (showStartVideo) {
    return (
      <PageWrapper>
        <Typography variant="h6" gutterBottom>
          {fields.startVideoTitle && fields.startVideoTitle[language]}
        </Typography>
        <Spacer />
        {fields.startVideoYoutubeId && fields.startVideoYoutubeId[language] && (
          <YoutubeEmbed id={fields.startVideoYoutubeId[language] || ""} />
        )}
        <Spacer />
        <Typography variant="h6" gutterBottom>
          {getDescription(
            fields?.startVideoDescription ? fields.startVideoDescription[language] : null
          )}
        </Typography>

        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => {
            setStartShowVideo(false);
            window.scrollTo(0, 0);
          }}
        >
          {fields.startVideoButton && fields.startVideoButton[language]}
        </StyledButton>
      </PageWrapper>
    );
  }

  if (end) {
    return (
      <PageWrapper>
        <Typography variant="h6" gutterBottom>
          {fields.endVideoTitle && fields.endVideoTitle[language]}
        </Typography>
        <Spacer />
        {fields.endVideoYoutubeId && fields.endVideoYoutubeId[language] && (
          <YoutubeEmbed id={fields.endVideoYoutubeId[language] || ""} />
        )}
        <Spacer />
        <Typography variant="h6" gutterBottom>
          {getDescription(
            fields?.endVideoDescription ? fields.endVideoDescription[language] : null
          )}
        </Typography>

        <StyledButton variant="contained" color="primary" onClick={handlePersistAndPush}>
          {fields.endVideoButton && fields.endVideoButton[language]}
        </StyledButton>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <CustomStepper activeStep={1} />
      <Spacer />
      <Typography variant="h6">{fields.title && fields.title[language]}</Typography>
      <Spacer />

      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <StyledButtonValue
            variant="contained"
            fullWidth
            size="large"
            color="primary"
            onClick={() => bump(value0)}
          >
            {value0.translations[language]
              ? value0.translations[language].value
              : value0.translations[DEFAULT_LANGUAGE].value}
          </StyledButtonValue>
        </Grid>

        <Grid item xs={12} sm={6}>
          <StyledButtonValue
            variant="contained"
            fullWidth
            size="large"
            color="primary"
            onClick={() => bump(value1)}
          >
            {value1.translations[language]
              ? value1.translations[language].value
              : value1.translations[DEFAULT_LANGUAGE].value}
          </StyledButtonValue>
        </Grid>
      </Grid>

      <Spacer i={2} />
      <LinearProgressWithLabel value={progress} />
    </PageWrapper>
  );
};

export default FocusPage;
